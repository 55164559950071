const themeColors = {
  ui: {
    ui_background: "#FFFFFF", // Default page background
    ui_01: "#F4F4F4", // Background on ui-background
    ui_02: "#FFFFFF", // Background on ui-01
    ui_03: "#E0E0E0", // Tertiary background, Subtle border
    ui_04: "#8D8D8D", // Element contrast, Medium contrast border
    ui_05: "#161616", // Emphasis elements
    ui_primary_highlight: "#772F7E", // Primary highlight colour -needs to change
    ui_secondary_highlight: "#84438a", //Secondary highlight colour
    ui_tertiary_highlight: "#EA8A32", // Tertiary highlight colour
    ui_light: "#ebe4ef",
    ui_focus: "#87D392", // focus highlight colour
    ui_divider: "#8D8D8D", // main divider colour
    ui_divider_subtle: "#E0E0E0", // Used when you need a more subtle divider
    ui_gradient_01: "linear-gradient(180deg, #E8FFED 0%, #E8FBFF 100%)", // Used In the menu and other key highlight areas
    ui_completed: "#21BF61", //light green
    ui_semi_complete: "#FFCF24", //light yellow
  },
  text: {
    text_primary: "#161616", // Primary text
    text_secondary: "#7d7a7a", // Secondary text colour
    text_tertiary: "#A8A8A8", // Tertiary text colour
    text_inverse: "#FFFFFF", // Used on a dark background
    text_link: "#772F7E", // Text link
    text_link_hover: "#84438a", // Hover for link
  },
  icons: {
    icon_colour_01: "#161616", // Primary icon colour
    icon_colour_02: "#525252", // Secondary icon colour
    icon_colour_03: "#A8A8A8", // Tertiary icon colour
    icon_colour_04: "#F4F4F4", // Inverse icon colour
    icon_colour_highlight_primary: "#772F7E", // Primary highlight icon colour
    icon_colour_highlight_secondary: "#84438a", // Secondary highlight icon colour
  },
  cards: {
    card_01: "#FFFFFF", // Primary card background
    card_02: "#ECECEC", // Secondary card background
    card_03: "#C4C4C4", // Tertiary card background
  },
  buttons: {
    primary_button: "#007200", // Hover colour on  grid elements
    primary_button_hover: "#198019", // Hover colour on primary buttons
    secondary_button_hover: "#51A1FB", // Hover colour on secondary buttons
  },
  status: {
    ui_error: "#E20909", // Used when there is a warning
    ui_error_hover: "#EA5353", // Used when there is a warning
    ui_success: "#1DC116", // Used when there is a warning
    ui_success_hover: "#80DE7C", // Used to indicate to the user a successfull operation on hover
    ui_information: "#51A1FB", // Provide additional information to users that may not be tied to their current action or task
    ui_information_hover: "#85BEFF", // Provide additional information to users that may not be tied to their current action or task
    ui_warning: "#E2B209", // Used when there is a warning
    ui_warning_hover: "#EBC645", // Used when there is a warning
  },
  gradients: {
    // gradient
    chart_vis_grad_01: "linear-gradient(270deg, #330574 0%, #7D2EED 100%)",
    chart_vis_grad_02: "linear-gradient(90deg, #1192E8 0%, #095689 100%)",
    chart_vis_grad_03: "linear-gradient(90deg, #03B0B5 0%, #026261 100%)",
    chart_vis_grad_04: "linear-gradient(270deg, #6A002E 0%, #9F1853 100%)",
    chart_vis_grad_05: "linear-gradient(90deg, #FA4D56 0%, #B4222A 100%)",
    chart_vis_grad_06: "linear-gradient(90deg, #B00A12 0%, #750308 100%)",
    chart_vis_grad_07: "linear-gradient(90deg, #198038 0%, #094B1D 100%)",
    chart_vis_grad_08: "linear-gradient(90deg, #002D9C 4.58%, #02174A 100%)",
    chart_vis_grad_09: "linear-gradient(90deg, #EE538B 0%, #B93464 100%)",
    chart_vis_grad_10: "linear-gradient(90deg, #B28600 0%, #7A5D06 100%)",
  },
};

// Export theme
export default themeColors;
