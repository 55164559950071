export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN;

export const API_ENDPOINTS = {
  GET_ALL_SURVEYS: `/products/survey/`,
  POST_SURVEY: `/products/survey/`,
  UPDATE_SURVEY: `/products/survey/?/`,
  DELETE_SURVEY: `/products/survey/?/`,
  GET_ALL_CLIENTS: `/products/client/`,
  GET_ALL_PRODUCT_CATEGORIES: `/products/product_category/`,
  GET_ALL_PRODUCTS: `/products/product/`,
  CLIENT_LOGIN: `/accounts/login/`,
  CLIENT_LOGOUT: `/accounts/logout/`,
  MEMBER_FILTER: `/products/login/`,
  REQUEST_TOKEN: `/accounts/reset-password/request/`,
  VERIFY_TOKEN: `/accounts/reset-password/verify/`,
  RESET_PASSWORD: `/accounts/reset-password/reset/`,
  CAPTCHA:`/captcha/`
};
