import { Box } from "@mui/material";
import AccordionDeNovo from "./AccordionDeNovo";

function TabDeNovo({ surveyData }) {
  return (
    <Box sx={{ padding: "0.3rem 2rem", }}>
      {surveyData &&
        surveyData.map((survey) => (
          <AccordionDeNovo key={survey.id} survey={survey} />
        ))}
    </Box>
  );
}

export default TabDeNovo;
