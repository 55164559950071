import { Routes, Route } from "react-router-dom";

import Login from "../pages/Login";
import Survey from "../pages/survey/index";
import Success from "../pages/Success";
import Error from "../pages/error";
import NotAvailable from "../pages/not_available";
import ResetPassword from "../pages/ResetPassword/ResetPassword";


function Router() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Login />}></Route>
        {/* <Route exact path="/:year/:surveyId" element={<Login />}></Route> */}
        <Route exact path="/survey" element={<Survey />}></Route>
        <Route exact path="/reset-password/" element={<ResetPassword />}></Route>
        {/* <Route
          exact
          path="/survey/:year/:surveyId"
          element={<Survey />}
        ></Route> */}
        <Route exact path="/success" element={<Success />}></Route>
        <Route exact path="/error" element={<Error />}></Route>
        <Route exact path="/not_available" element={<NotAvailable />}></Route>
        {/* <Route exact path="/files" element={<Files />}></Route> */}
        <Route path="*" element={<Login />}></Route>
      </Routes>
    </>
  );
}

export default Router;
