import { Box } from "@mui/material";
import Accordion510k from "./Accordion510k";

function Tab510k({ surveyData }) {

  return (
    <Box sx={{ padding: "0.3rem 2rem" }}>
      {surveyData &&
        surveyData.map((survey) => (
          <Accordion510k key={survey.id} survey={survey} />
        ))}
    </Box>
  );
}

export default Tab510k;
