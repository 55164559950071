import { useState, useRef, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
    TextField,
    Button,
    CircularProgress,
    Box,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { themeColors, themeTypography } from "../themes";
import {
    loginService,
    requestToken,
    resetPasswordRequest,
    memberFilter,
    captchaService,
} from "../services";

const LoginButton = styled(Button)((props) => ({
    background: themeColors.ui.ui_primary_highlight,
    // marginLeft: "0.5rem",
    padding: "0 1.5rem",
    "&:hover": {
        background: "#80669d",
    },
    borderRadius: "8px",
    height: "35px",
    width: "50%",
}));

const ForgotPasswordButton = styled(Button)((props) => ({
    background: "#a881af",
    // marginLeft: "0.5rem",
    padding: "0 1.5rem",
    "&:hover": {
        background: "#80669d",
    },
    borderRadius: "8px",
    height: "35px",
    width: "50%",
}));

const StyledModalDiv = styled("div")({
    width: "90%",
    margin: "5px",
    display: "flex",
    justifyContent: "center",
});

const StyledImage = styled("img")({});

const StyledH1 = styled("h1")({
    ...themeTypography.headings.h1,
    color: themeColors.ui.ui_primary_highlight,
    paddingTop: "7rem",
});

const StyledH2 = styled("h2")({
    ...themeTypography.headings.header2,
    color: themeColors.text.text_secondary,
    marginBottom: "0.5rem",
});

const StyledTextFieldDiv = styled("div")({
    width: "100%",
    margin: "10px",
    display: "flex",
    justifyContent: "center",
});

const StyledInput = styled(TextField)({
    width: "50%",
    background: themeColors.ui.ui_background,
    "& label.Mui-focused": {
        color: themeColors.ui.ui_primary_highlight,
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            borderColor: themeColors.ui.ui_primary_highlight,
        },
    },
});

const StyledH5Error = styled("h5")({
    color: themeColors.status.ui_error,
    margin: "0.6rem 0 0 0",
});

function Login() {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [warningText, setWarningText] = useState("");
    const [warningColor, setWarningColor] = useState(`${themeColors.ui.ui_01}`);
    const [forgotEmail, setForgotEmail] = useState("");

    const [spinner, setSpinner] = useState(false);
    const [enableLogin, setEnableLogin] = useState(true);
    const recaptchaRef = useRef(null);
    const [action, setAction] = useState("login");
    const [client, setClient] = useState({});
    const [captchaValue, setCaptchaValue] = useState("");

    const openLoginCaptcha = (e) => {
        recaptchaRef.current.execute();
        setAction("login");
    };

    useEffect(() => {
        callCaptchaService();
    }, []);

    const callCaptchaService = () => {
        captchaService()
            .then((res) => {
                setClient(res.data);
                setEnableLogin(true);
            })
            .catch(() => {
                setEnableLogin(false);
            });
    };

    const handleCaptchaValueChange = (e) => setCaptchaValue(e.target.value);

    const handleEmailChange = (e) => setEmail(e.target.value);
    if (email === null) {
        setWarningText("The email or the password is incorrect.");
    }
    const handlePasswordChange = (e) => setPassword(e.target.value);
    if (password === null) {
        setWarningText("Please enter correct password");
    }

    const handleForgotEmailChange = (e) => {
        setForgotEmail(e?.target?.value ? e?.target?.value.toLowerCase() : "");
    };

    const handleLoginBtnClick = () => {
        loginService({
            email: email ? email.toLowerCase() : "",
            password: password,
            captcha_key: client.captcha_key,
            captcha_value: captchaValue,
        })
            .then((res) => {
                localStorage.setItem("auth_token", res.data.access);
                memberFilter({ email: email ? email.toLowerCase() : "" }).then(
                    (res) => {
                        localStorage.setItem("token", res.data.token);
                        localStorage.setItem(
                            "clientName",
                            res.data.member_name
                        );
                        callCaptchaService();
                        localStorage.setItem("memberId", res.data.member_id);
                        navigate(`/survey`);
                    }
                );
            })
            .catch((e) => {
                setWarningText(
                    e?.response?.data?.detail
                        ? e?.response?.data?.detail
                        : "Invalid Credentials"
                );
                setWarningColor(`${themeColors.status.ui_error}`);
                callCaptchaService();
            });
    };
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setCaptchaValue("");
        callCaptchaService();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSendEmail = () => {
        setSpinner(true);
        requestToken({
            email: forgotEmail,
            captcha_key: client.captcha_key,
            captcha_value: captchaValue,
        })
            .then((res) => {
                setForgotEmail("");
                handleClose();

                setSpinner(false);
            })
            .catch(() => {
                setForgotEmail("");
                handleClose();

                setSpinner(false);
            });
    };

    return (
        <>
            <Box
                component={"section"}
                sx={{
                    height: "calc(100vh - 3rem)",
                    padding: "0 5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    background: themeColors.ui.ui_01,
                }}
            >
                <StyledH1>AdvaMed FDA Submission Database</StyledH1>
                <StyledH2>Get started</StyledH2>

                <Box width={"60%"}>
                    <StyledTextFieldDiv>
                        <StyledInput
                            id="email-input"
                            label="Enter email"
                            variant="outlined"
                            size="small"
                            onChange={handleEmailChange}
                        />
                    </StyledTextFieldDiv>

                    <StyledTextFieldDiv>
                        <StyledInput
                            id="password-input"
                            label="Enter password"
                            variant="outlined"
                            size="small"
                            type="password"
                            onChange={handlePasswordChange}
                        />
                    </StyledTextFieldDiv>

                    <StyledTextFieldDiv>
                        <StyledImage
                            src={
                                "data:image/png;base64," + client.captcha_image
                            }
                            sx={{ width: "50%" }}
                        />
                    </StyledTextFieldDiv>

                    <StyledTextFieldDiv>
                        <StyledInput
                            id="captcha-input"
                            label="Enter text shown in above image"
                            variant="outlined"
                            size="small"
                            type="text"
                            onChange={handleCaptchaValueChange}
                        />
                    </StyledTextFieldDiv>

                    <StyledTextFieldDiv>
                        <LoginButton
                            key={"login"}
                            variant="contained"
                            onClick={handleLoginBtnClick}
                        >
                            Login
                        </LoginButton>
                    </StyledTextFieldDiv>

                    <StyledTextFieldDiv>
                        <ForgotPasswordButton
                            variant="contained"
                            onClick={handleClickOpen}
                        >
                            Forgot/Reset Password
                        </ForgotPasswordButton>

                        <Box>
                            <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>Reset Password</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        If you are already registered, you will
                                        receive a link on your email to reset
                                        your password.
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="name"
                                        label="Email Address"
                                        type="email"
                                        onChange={handleForgotEmailChange}
                                        fullWidth
                                        variant="standard"
                                        disabled={spinner}
                                    />
                                    <StyledModalDiv>
                                        <StyledImage
                                            width={"100%"}
                                            src={
                                                "data:image/png;base64," +
                                                client.captcha_image
                                            }
                                        />
                                    </StyledModalDiv>
                                    <StyledModalDiv>
                                        <StyledInput
                                            id="captcha-input"
                                            label="Enter text shown in above image"
                                            variant="outlined"
                                            size="small"
                                            type="text"
                                            onChange={handleCaptchaValueChange}
                                        />
                                    </StyledModalDiv>
                                </DialogContent>
                                {spinner && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            right: "50%",
                                            top: "40%",
                                        }}
                                    >
                                        <CircularProgress />
                                    </Box>
                                )}
                                <DialogActions>
                                    <Button
                                        disabled={spinner}
                                        onClick={handleClose}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        disabled={spinner}
                                        onClick={handleSendEmail}
                                    >
                                        Send Email
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </StyledTextFieldDiv>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <WarningIcon
                        fontSize="small"
                        sx={{
                            color: warningColor,
                            margin: "0.5rem 0.3rem 0 0",
                        }}
                    />
                    <StyledH5Error>{warningText}</StyledH5Error>
                </Box>
            </Box>
        </>
    );
}

export default Login;
