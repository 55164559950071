import { Box } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

import { themeColors } from "../themes";

function Error() {
  return (
    <Box sx={{ 
      height: "90vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <ErrorIcon
        fontSize="large"
        sx={{ color: themeColors.status.ui_error }}
      />
      <h1>An Error occured!! Please try again later</h1>
    </Box>
  );
}

export default Error;
