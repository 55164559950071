import ErrorIcon from '@mui/icons-material/Error';

import { themeColors } from "../themes";
import { Box } from '@mui/material';

function NotAvailable() {
  return (
    <Box sx={{
      height: "90vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <ErrorIcon
        fontSize="large"
        sx={{ color: themeColors.status.ui_error }}
      />
      <h1>Survey Not Available For The User!</h1>
    </Box>
  );
}

export default NotAvailable;
