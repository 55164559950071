import React, { Component, useEffect,useState } from 'react'
import { Formik } from 'formik'
import { object, ref, string } from 'yup'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Paper from '@material-ui/core/Paper'
import { purple } from '@mui/material/colors';
import Spinner from './Spinner'
import Alert from './Alert'
import './styles.css'
import { verifyToken,resetPassword } from "../../services";


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),

  backgroundColor: purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
  '&.Mui-disabled':{
    backgroundColor: purple[100],
  }
}));
export default class ResetPassword extends Component {
  
  
  state = {
    passChangeSuccess: false,
    tokenIsValid: false,
    error:[]
   
  }

  _handleModalClose = () => {
    this.setState(() => ({
      ...this.state,
      passChangeSuccess: false,
    }))
  }



  _renderModal = () => {
    const onClick = () => {
      this.setState(() => ({ ...this.state,passChangeSuccess: false }))
      
    window.location.href = '/'
    }



    return (
      <Alert
        isOpen={this.state.passChangeSuccess}
        onClose={this._handleClose}
        handleSubmit={onClick}
        title="Password Reset"
        text="Your password was changed successfully"
        submitButtonText="Done"
      />
    )
  }

  _handleSubmit = ({
   
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
  }) => {
    // fake async login
    setTimeout(async () => {
      setSubmitting(false)
      const url= window.location.href.split('/')[4]
      const urlToken = url.substring(1);
      resetPassword({token:urlToken,new_password:newPass,confirm_password:confirmPass}).then((res)=>
     
        this.setState(() => ({
          ...this.state,
          passChangeSuccess: true,
          error:[]
        }))
        
      ).catch((e) => {
        // setTokenIsValid(false)
        console.log(e)
        this.setState(() => ({
          ...this.state,
          passChangeSuccess: false,
          error: e.response.data.non_field_errors,
        }))
      });
     
      resetForm()
    }, 1000)
  }
  componentDidMount=()=>{
    // const search = useLocation().search;
   const url= window.location.href.split('/')[4]
   const urlToken = url.substring(1);
    verifyToken({ token: urlToken })
    .then((res) => {
      this.setState(() => ({
        ...this.state,
        tokenIsValid: true,
      }))
    })
    .catch(() => {
      // setTokenIsValid(false)
      console.log("Error logging In")
    });
    }
  render() {
          return(<div className="App">
       
        
      {this.state.tokenIsValid ?<Formik
        initialValues={{
          newPass: '',
          confirmPass: '',
        }}
        validationSchema={object().shape({
          newPass: string().required('New password is required'),
          confirmPass: string()
            .oneOf([ref('newPass')], 'Passwords do not match')
            .required('Password is required'),
        })}
        onSubmit={(
          { newPass, confirmPass },
          { setSubmitting, resetForm }
        ) =>
          this._handleSubmit({
            newPass,
            confirmPass,
            setSubmitting,
            resetForm,
          })
        }
        render={props => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
          } = props
          return isSubmitting ? (
            <Spinner />
          ) : (
            <Paper className="form form--wrapper" elevation={10}>
              <form className="form" onSubmit={handleSubmit}>
                
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.newPass && errors.newPass)}
                >
                  <InputLabel
                    htmlFor="password-new"
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {'New Password'}
                  </InputLabel>
                  <Input
                    id="password-new"
                    name="newPass"
                    type="password"
                    value={values.newPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.newPass && errors.newPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.newPass && errors.newPass)}
                  >
                    {touched.newPass && errors.newPass ? errors.newPass : ''}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="dense"
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                >
                  <InputLabel
                    htmlFor="password-confirm"
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {'Confirm Password'}
                  </InputLabel>
                  <Input
                    id="password-confirm"
                    name="confirmPass"
                    type="password"
                    value={values.confirmPass}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  />
                  <FormHelperText
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                  >
                    {touched.confirmPass && errors.confirmPass
                      ? errors.confirmPass
                      : ''}
                  </FormHelperText>
                  {this.state.error.length>0 && <FormHelperText error={true}>{this.state.error}</FormHelperText>}
                </FormControl>
                <ColorButton
                  type="submit"
                  variant="raised"
                  disabled={Boolean(!isValid || isSubmitting)}
                  style={{ margin: '16px' }}
                >
                  {'Reset Password'}
                </ColorButton>
              </form>
              {this._renderModal()}
            </Paper>
          )
        }}
      />:(<div>Token has expired. Please Try Again</div>)}
      </div>)
    
  }
}

