import themeColors from "./ThemeColors";

const themeTypography = {
  body: {
    body_text_01: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      color: themeColors.text.text_primary,
    },
    body_text_02: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#525252",
    },
    body_text_03: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#8D8D8D",
    },
    label_01: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "16px",
      color: "#525252",
    },
    label_02: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "28px",
      lineHeight: "33px",
      color: themeColors.text.text_secondary,
    },
  },
  headings: {
    h1: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "40px",
      lineHeight: "47px",
      color: "#121619",
    },
    h2: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "24px",
      lineHeight: "28px",
      color: "#121619",
    },
    h3: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "20px",
      lineHeight: "23px",

      color: "#121619",
    },
    h4: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "16px",
      lineHeight: "19px",
      color: "#000000",
    },
    h5: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      color: "#121212",
    },
    h6: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "14px",
      color: "#000000",
    },
  },
};

export default themeTypography;
