import { CssBaseline } from "@mui/material";
import AppHeader from "../components/AppHeader";

import Router from "../router/Router";

function Site() {
  return (
    <>
      <CssBaseline />
      <AppHeader />
      <Router />
    </>
  );
}
export default Site;
