import moment from 'moment';
export const dateFormat = (date) => {
    if (date == undefined) {
      return "";
    }
    let newDate = "" + date;
    let year = newDate.slice(0, 4);
    let month = newDate.slice(5, 7);
    let day = newDate.slice(8, 10);
    let currentDate = month + "/" + day + "/" + year;
    return currentDate;
  };


  export const dateFormatYMD = (date) => {
    if (date == undefined) {
      return "";
    }
    let year = date.getFullYear()
    let month = date.getMonth()
    let day = date.getDate();
    let currentDate = year + "-" + month + "-" + day;
    return currentDate;
  };

  export const stringDateToFormat = (date,dateFormat) =>{

   const newDate = new Date(date).toDateString();
    if(moment(newDate).isValid())
    return moment(newDate).format(dateFormat);
    else
    return null

  }
  export const fiscalYearFormat = (date,dateFormat) =>{

    const newDate = new Date(date).toDateString();
     const month = (moment(newDate).format(dateFormat)).toString()[5]+(moment(newDate).format(dateFormat)).toString()[6];
     let year=(moment(newDate).format(dateFormat)).toString()[2]+(moment(newDate).format(dateFormat)).toString()[3];

     if(Number(month)>9){
  year = (Number(year)+1).toString()
 }
 return 'FY'+year
   }

export const add3dots=(str)=>{
    if (str && str.length>50){
        str = str.substr(0,50) + "..."
    }
    return str
  }

  export const dateFormatFe = (date) => {
    if (date == undefined) {
      return "";
    }
    let year = ''+ date.$y;
    let month =date.$M+1;
    month=''+month
    let day = '' + date.$D;
    if(day.length==1){
      day="0"+day
    }
    if(month.length==1){
      month="0"+month
    }
    let currentDate = year + "-" + month + "-" + day;
    return currentDate;
  };