import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/system";

import AdvaMedLogo from "../media/images/advamed_logo.png";
import { themeColors } from "../themes";
import { logoutService } from "../services";

const Layout = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const H3 = styled("h3")({
  marginRight: "2.5rem",
  color: themeColors.ui.ui_primary_highlight,
  fontStyle: "italic",
});

const H4 = styled("h4")({
  color: themeColors.text.text_secondary,
});

const StyledImage = styled("img")({
  padding: "0 0 0 2rem",
  cursor: "pointer",
  height: "3rem",
});

function AppHeader() {
  const navigate = useNavigate();
  const gotoHome = () => navigate(`/`);
  const logout = () => {
    logoutService({ token: localStorage.getItem("auth_token") });
    localStorage.setItem("auth_token", null);
    localStorage.setItem("token", null);
    localStorage.setItem("clientName", "");
    gotoHome();
  };

  const goToSurvey =() =>{
    navigate(`/survey`);
  }

  const logoutArray = ["/", "/not_available", "/error", "/reset-password/"];
  const period = "MDUFA V Decisions";

  return (
    <Layout>
      <StyledImage
        src={AdvaMedLogo}
        onClick={gotoHome}
      />
      <H4>
        {window.location.pathname === "/" ||
        window.location.pathname === "/reset-password/"
          ? null
          : period}
        {window.location.pathname !== "/reset-password/"
          ? null
          : "AdvaMed Password Reset"}
      </H4>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <H3>
          {window.location.pathname === "/"
            ? null
            : localStorage.getItem("clientName")}
        </H3>
        {window.location.pathname === "/success" &&
           <Button
           variant="contained"
           onClick={goToSurvey}
           sx={{
             background: themeColors.ui.ui_primary_highlight,
             height: "2.5rem",
             marginRight: "2rem",
             borderRadius: "8px",
           }}
         >
           Go back to Survey
         </Button>
        }
        {logoutArray.includes(window.location.pathname) ? null : (
          <Button
            variant="contained"
            onClick={logout}
            sx={{
              background: themeColors.ui.ui_primary_highlight,
              height: "2.5rem",
              marginRight: "2rem",
              borderRadius: "8px",
            }}
          >
            Logout
          </Button>
        )}
      </Box>
    </Layout>
  );
}

export default AppHeader;
