import React from "react";
import ReactDOM from "react-dom/client";
import { CacheProvider } from "@emotion/react";

import App from "./App";
import { createEmotionCache } from "./utils/createEmotionCache";

const meta = document.getElementsByName("csp_nonce")[0]
let nonce = null;
if (meta) {
  nonce = meta.content;
}
const emotionCache = createEmotionCache(nonce);


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CacheProvider value={emotionCache}>
      <App />
    </CacheProvider>
  </React.StrictMode>
);
