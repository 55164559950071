import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { themeColors } from "../themes";
import { Box } from "@mui/material";

function Success() {
  return (
    <Box sx={{
      height: "90vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}>
      <CheckCircleIcon
        fontSize="large"
        sx={{ color: themeColors.buttons.primary_button }}
      />
      <h1>You have successfully submitted the survey</h1>
    </Box>
  );
}

export default Success;
