import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Box, Button, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate, useParams } from "react-router-dom";

import { themeColors, themeTypography } from "../../themes";
import { getAllSurveysService } from "../../services";
import Tab510k from "./Tab510k";
import TabDeNovo from "./TabDeNovo";
import TabPMA from "./TabPMA";

const StyledTabs = styled(Tabs)({
  height: "1.8rem",
  minHeight: "1rem",
  "& .MuiTab-root": {
    height: "10px",
    minWidth: "60px",
    marginRight: "1rem",
    borderRadius: "7px",
    paddingBottom: "2rem",
    color: themeColors.ui.ui_primary_highlight,
    background: themeColors.ui.ui_02,
  },
  "& .Mui-selected": {
    color: `${themeColors.text.text_inverse} !important`,
    background: themeColors.ui.ui_primary_highlight,
  },
  "& .MuiTabs-indicator": {
    color: themeColors.text.text_inverse,
    backgroundColor: themeColors.ui.ui_primary_highlight,
  },
});

const SubmitButton = styled(Button)({
  background: themeColors.ui.ui_primary_highlight,
  padding: "0.5rem 1rem",
  position: "fixed",
  right: "2rem",
  bottom: "2rem",
  "&:hover": {
    background: themeColors.ui.ui_secondary_highlight,
  },
});

const AddButton = styled(Button)({
  padding: "0.5rem 0.5rem 0.5rem 0.5rem",
  position: "fixed",
  left: "2rem",
  bottom: "2rem",
  background: themeColors.ui.ui_02,
  color: themeColors.ui.ui_primary_highlight,
});

function Survey() {
  const { surveyId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [surveyData510k, setSurveyData510k] = useState();
  const [surveyDataDeNovo, setSurveyDataDeNovo] = useState();
  const [surveyDataPMA, setSurveyDataPMA] = useState();
  const [alignment, setAlignment] = useState(
    // JSON.parse(localStorage.getItem("alignment"))
    true
  );
  const [flag510k, setFlag510k] = useState(false);
  const [flagDeNOVO, setFlagDeNOVO] = useState(false);
  const [flagPMA, setFlagPMA] = useState(false);
  const [newTab510k, setNewTab510k] = useState(1);
  const [newTabDeNOVO, setNewTabDeNOVO] = useState(1);
  const [newTabPMA, setNewTabPMA] = useState(1);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setShowLoader(true);
    localStorage.setItem("alignment", alignment);
    getAllSurveysService(
      { Authorization: localStorage.getItem("auth_token") },
      alignment ? "Yes" : "No",
      surveyId
    )
      .then((res) => {
        setTimeout(() => {
          setSurveyData510k(
            res.data.filter((elem) => elem.product_category === "510(k)")
          );
          setSurveyDataDeNovo(
            res.data.filter((elem) => elem.product_category === "De Novo")
          );
          setSurveyDataPMA(
            res.data.filter((elem) => elem.product_category === "PMA")
          );
          setShowLoader(false);
        }, 500);
      })
      .catch(() => showErrorPage());
  }, [alignment, activeTab]);

  const changeActiveTab = (e, newValue) => {
    setActiveTab(newValue);
  };
  const showSuccessPage = () => navigate(`/success`);
  const showErrorPage = () => navigate(`/error`);
  const tabNumFunc510k = () => setFlag510k(true);
  const tabNumFuncDeNOVO = () => setFlagDeNOVO(true);
  const tabNumFuncPMA = () => setFlagPMA(true);
  const handleAddBtnClick = () => {
    if (activeTab === 0) setNewTab510k(newTab510k + 1);
    if (activeTab === 1) setNewTabDeNOVO(newTabDeNOVO + 1);
    if (activeTab === 2) setNewTabPMA(newTabPMA + 1);
  };

  if (flag510k == true && newTab510k > 1) {
    setNewTab510k(newTab510k - 1);
    setFlag510k(false);
  }
  if (flagDeNOVO == true && newTabDeNOVO > 1) {
    setNewTabDeNOVO(newTabDeNOVO - 1);
    setFlagDeNOVO(false);
  }
  if (flagPMA == true && newTabPMA > 1) {
    setNewTabPMA(newTabPMA - 1);
    setFlagPMA(false);
  }

  return (
    <Box sx={{ background: `${themeColors.ui.ui_01}`, paddingBottom: "40%", }}>
      <Grid container spacing={2} sx={{ paddingBottom: "1rem", width: "100%" }}>
        <Grid xs={6.65}>
          <Box sx={{
            borderBottom: 1,
            borderColor: "divider",
            paddingRight: "1rem",
            margin: "0 0 0 2rem",
            paddingTop: "1rem",
          }}>
            <StyledTabs value={activeTab} onChange={changeActiveTab}>
              <Tab sx={{ textTransform: "none" }} label="510(k)" />
              <Tab label="De Novo" />
              <Tab label="PMA" />
            </StyledTabs>
          </Box>
        </Grid>
        {/* temporarily commented as per instructions */}
        {/* <Grid xs={5.35} sx={{ marginTop: "1rem" }}>
          <Box sx={{ display: "flex", marginLeft: "1rem" }}>
            <ToggleButtonGroup
              color="secondary"
              exclusive
              aria-label="Platform"
              size="small"
              value={alignment}
            >
              <ToggleButton
                onClick={() => {
                  setAlignment(true);
                }}
                value={true}
              >
                Submissions with a Positive FDA Decision
              </ToggleButton>
              <ToggleButton
                onClick={() => {
                  setAlignment(false);
                }}
                value={false}
              >
                Submissions with a Negative FDA decision
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Grid> */}
      </Grid>

      {
        showLoader ? (
          <Box sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
            <CircularProgress
              sx={{ color: themeColors.ui.ui_secondary_highlight }}
            />
          </Box>
        ) : alignment &&
          (surveyData510k?.length !== 0 ||
            surveyDataDeNovo?.length !== 0 ||
            surveyDataPMA?.length !== 0) ? (
          // submission with positive FDA descision UI
          <>
            <TabPanel value={activeTab} index={0}>
              <Tab510k surveyData={surveyData510k} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <TabDeNovo surveyData={surveyDataDeNovo} />
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <TabPMA surveyData={surveyDataPMA} />
            </TabPanel>

            <SubmitButton
              onClick={() =>
                activeTab < 2 ? setActiveTab(activeTab + 1) : showSuccessPage()
              }
              variant="contained"
            >
              {activeTab === 2 ? "Submit & Finish" : "Next"}
            </SubmitButton>
          </>
        ) : (
          <Box sx={{
            position: "absolute",
            top: "45%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            ...themeTypography.headings.h2,
            color: themeColors.ui.ui_primary_highlight,
          }}>
            No data to display. Please visit after sometime
          </Box>
        )
        // temporarily commented as per instructions
        // (
        //   // submission with negative FDA descision UI
        //   <>
        //     <TabPanel value={activeTab} index={0}>
        //       <NewTab510k
        //         newTab510k={newTab510k}
        //         surveyData={surveyData510k}
        //         tabNumFunc510k={tabNumFunc510k}
        //       />
        //     </TabPanel>
        //     <TabPanel value={activeTab} index={1}>
        //       <NewTabDeNOVO
        //         newTabDeNOVO={newTabDeNOVO}
        //         surveyData={surveyDataDeNovo}
        //         tabNumFuncDeNOVO={tabNumFuncDeNOVO}
        //       />
        //     </TabPanel>
        //     <TabPanel value={activeTab} index={2}>
        //       <NewTabPMA
        //         newTabPMA={newTabPMA}
        //         surveyData={surveyDataPMA}
        //         tabNumFuncPMA={tabNumFuncPMA}
        //       />
        //     </TabPanel>
        //     <Grid>
        //       <AddButton onClick={handleAddBtnClick}>
        //         <AddCircleIcon fontSize="large" />
        //         Add New Record
        //       </AddButton>
        //       <SubmitButton
        //         onClick={() =>
        //           activeTab < 2 ? setActiveTab(activeTab + 1) : showSuccessPage()
        //         }
        //         variant="contained"
        //       >
        //         {activeTab === 2 ? "Submit & Finish" : "Fill Next"}
        //       </SubmitButton>
        //     </Grid>
        //   </>
        // )
      }
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default Survey;
