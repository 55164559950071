import axios from "axios";

import { API_DOMAIN, API_ENDPOINTS } from "./api-constants";

export function captchaService() {
  return axios.post(`${API_DOMAIN}${API_ENDPOINTS.CAPTCHA}`);
}
export function requestToken(data) {
  return axios.put(`${API_DOMAIN}${API_ENDPOINTS.REQUEST_TOKEN}`, data);
}

export function loginService(data) {
  return axios.post(`${API_DOMAIN}${API_ENDPOINTS.CLIENT_LOGIN}`, data);
}
export function logoutService(data) {
  return axios.post(`${API_DOMAIN}${API_ENDPOINTS.CLIENT_LOGOUT}`, data);
}
export function memberFilter(data) {
  return axios.post(`${API_DOMAIN}${API_ENDPOINTS.MEMBER_FILTER}`, data);
}
export function resetPasswordRequest(data) {
  return axios.put(
    `${API_DOMAIN}${API_ENDPOINTS.RESET_PASSWORD_REQUEST}`,
    data
  );
}
export function verifyToken(data) {
  return axios.post(`${API_DOMAIN}${API_ENDPOINTS.VERIFY_TOKEN}`, data);
}
export function resetPassword(data) {
  return axios.post(`${API_DOMAIN}${API_ENDPOINTS.RESET_PASSWORD}`, data);
}
export function getAllSurveysService(data, field_positive, surveyId) {
  const memberId = localStorage.getItem("memberId");
  return axios.get(`${API_DOMAIN}${API_ENDPOINTS.GET_ALL_SURVEYS}`, {
    headers: data,
    params:
      surveyId === "undefined"
        ? { client_id: memberId, field_positive: field_positive }
        : {
            client_id: memberId,
            field_positive: field_positive,
            surveyId: surveyId,
          },
  });
}

export function updateSurveyService(survey_id, data) {
  return axios.patch(
    `${API_DOMAIN}${API_ENDPOINTS.UPDATE_SURVEY.replace("?", survey_id)}`,
    data,
    {
      headers: {
        Authorization: localStorage.getItem("auth_token"),
      },
    }
  );
}

export function deleteSurveyService(survey_id) {
  return axios.delete(
    `${API_DOMAIN}${API_ENDPOINTS.DELETE_SURVEY.replace("?", survey_id)}`
  );
}

export function postNegativeSurveyService(data) {
  return axios.post(`${API_DOMAIN}${API_ENDPOINTS.POST_SURVEY}`, {
    ...data,
  });
}
